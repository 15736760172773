import { Container } from "components/container/Container";
import React from "react";
import s from "./ImagesCarousel.scss";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import NextIcon from 'assets/svg/carousel/carousel-next.svg';
import PrevIcon from 'assets/svg/carousel/carousel-prev.svg';
import { GatsbyImage } from "gatsby-plugin-image";
import './carouselOverride.css';
import { RichText } from "components/contentful/rich-text/RichText";
import { renderRichText } from "gatsby-source-contentful/rich-text";


const Prev = () => <PrevIcon className={s.button + ' ' + s.prev}/>
const Next = () => <NextIcon className={s.button + ' ' + s.next}/>

export const ImagesCarousel = ({list, style}: any) => {
    const {items, title} = list;
    const images = items.map((item:any, i:number) =>
        <div className={s.itemContainer}>
            <GatsbyImage
                alt=""
                image={item.image.gatsbyImageData}
                className={s.image}
            />
            {
                item.text &&
                <div className={s.caption}>
                    {renderRichText(item.text)}
                </div>
            }
        </div>
    );

    return (
        <Container>
            <div className={'quotes-carousel ' +s .container} style={style}>
            {
                title &&
                <h2 className={s.title}>{title}</h2>
            }
                <AliceCarousel
                    items={images}
                    mouseTracking={!(items.length < 2)}
                    infinite
                    disableDotsControls={(items.length < 2)}
                    responsive={{
                        0: {
                            items: 1
                        }
                    }}
                    disableButtonsControls={(items.length < 2)}
                    renderNextButton={Next}
                    renderPrevButton={Prev}
                />
            </div>
        </Container>
    )
}