import React, { useEffect, useRef } from "react";
import s from "./Line5.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line5 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top bottom",
          end: "-=100px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="597"
      height="478"
      viewBox="0 0 597 478"
      fill="none"
    >
      <path
        ref={lineRef}
        opacity="0.2"
        d="M596 136V341.517C596 416.075 535.558 476.517 461 476.517M-454 136V341.517C-454 416.075 -393.558 476.517 -319 476.517M595.517 136C595.517 61.4416 535.075 1 460.517 1H-318.517C-393.075 1 -453.517 61.4416 -453.517 136M461 476.5H-319"
        stroke="#1B52AD"
        stroke-width="2"
      />
    </svg>
  );
};
