import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';
import { RichText } from 'components/contentful/rich-text/RichText';

import { Container } from 'components/container/Container';
import { PrismicImage } from 'types/prismicImage';

import { UIContext } from 'context/ui';

import { scroll } from 'constants/Scroll'

import s from './List.scss';

interface IProps {
  title: string;
  items?: Array<IItemProps> | null;
  centerTitle?: boolean;
  itemColumns?: boolean;
  background?: boolean;
  darker?: boolean;
  noPaddingTop?: boolean;
}

interface IItemProps {
  title: Array<{ text: string }>;
  richtext: any;
  icon: PrismicImage;
  
}

export const ListContentful = ({
  list,
  centerTitle,
  itemColumns,
  background=false,
  darker=false,
  noPaddingTop=false,
  style }: any) => {

  const { isMobile, isDesktop } = useContext(UIContext);
  const featuresRef = useRef<HTMLUListElement>(null);
  let tl:GSAPTimeline;

  const items = list.items
  const title = list.title

  useEffect(():any => {
    if (featuresRef.current) {
      if (isDesktop) {
        setTimeout(() => {
          const listItemImages = featuresRef.current.querySelectorAll('.js-l-img');
          const listItemDescriptions = featuresRef.current.querySelectorAll('.js-l-info');

          tl = gsap.timeline({
            scrollTrigger: {
              scroller: scroll.container,
              trigger: featuresRef.current
            }
          });

          tl
            .addLabel('start')
            .set(listItemImages, { scale: 0.7 })
            .to(listItemImages, {
              duration: 0.6,
              ease: 'Power3.easeInOut',
              // stagger: 0.3,
              stagger: {
                amount: 0.3,
                from: 'start',
                grid: [4,2],
                axis: 'y',
              },
              autoAlpha: 1,
              scale: 1
            }, 'start')
            .to(listItemDescriptions, {
            duration: 0.6,
            // stagger: 0.3,
            stagger: {
              amount: 0.3,
              from: 'start',
              grid: [4,2],
              axis: 'y',
            },
            ease: 'Power3.easeInOut',
            autoAlpha: 1
          }, 'start+=0.1')
        }, 1500);
      }

      if (isMobile) {
        setTimeout(() => {
          const listItems = featuresRef.current.querySelectorAll('.js-t-list');

          listItems.forEach((item) => {
            const image = item.querySelector('.js-l-img');
            const description = item.querySelector('.js-l-info');

            if (image && description) {
              gsap.fromTo(
                image,
                { autoAlpha: 0, scale: 0.7 },
                {
                  scrollTrigger: image,
                  duration: 0.6,
                  ease: 'Power3.easeInOut',
                  autoAlpha: 1,
                  scale: 1
                }
              );

              gsap.fromTo(
                description,
                { autoAlpha: 0 },
                {
                  scrollTrigger: description,
                  duration: 0.6,
                  ease: 'Power3.easeInOut',
                  autoAlpha: 1,
                }
              );
            }
          });
        }, 1500);
      }
    }

    return () => tl && tl.kill()
  }, [isMobile, isDesktop])

  const content = items?.map((item: any, index: number) => {
    return (
      <li key={index} className={`${s.list__item} | js-t-list`}>
        <figure className={`${s.list__itemIcon} | js-l-img`}>
          <img src={item.image?.file.url} alt={item.image?.description} />
        </figure>
        <div className={`${s.list__itemContent} | js-l-info`}>
          <h4 className={s.list__itemTitle}>{item.title}</h4>
          {item.text && <RichText richText={item.text}/>}
        </div>
      </li>
    );
  });

  return (
    <div className={s('list', {centerTitle, itemColumns, background, darker, noPaddingTop})} style={style}>
      <Container>
        <div className={s.list__row_top}>
          <div className={s.list__col_top}>
            <h3 className={s.list__title} id="list-heading">{title}</h3>
          </div>
        </div>
        <div className={s.list__row_bottom}>
          <div className={s.list__col_bottom}>
            <ul ref={featuresRef} className={s.list__items} aria-labelledby="list-heading">
              {content}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};
