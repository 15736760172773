import React, { useEffect, useRef } from "react";
import s from "./Line1.scss";

import gsap from "gsap";
import { scroll } from "constants/Scroll";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

export const Line1 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear"
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="1391"
      height="825"
      viewBox="0 0 1391 825"
      fill="none"
    >
      <path
        ref={lineRef}
        opacity="0.2"
        d="M846.508 823.103H1190C1300.46 823.103 1390 733.56 1390 623.103L1390 -275C1390 -385.457 1300.46 -475 1190 -475L-623 -475C-733.457 -475 -823 -385.457 -823 -275L-823 -235M847 823.5H-71.9998M0.10321 823.492H-623C-733.457 823.492 -823 733.949 -823 623.492V-234.5"
        stroke="#1B52AD"
        stroke-width="2"
      />
    </svg>
  );
};
